import {create} from 'zustand'
import { CARD_SORT_TYPE, CARD_TYPE, DEFAULT_FILTERS, ICategory, ICritery, IFestFilters, IFestival, IFormField, IGanre, IHoneycombs, IMilestone, ILand, IProject, IProjectMilestone, ITrack } from './interfaces'
import { createJSONStorage, devtools, persist } from 'zustand/middleware' 
import { ID } from "src/libs/interfaces/layouts";   

export const useFestAdminStore: any = create(devtools<IFestAdminStore>((set: any, get: any ) => ({ 
    client: { },
    setClient: ( client: any ) => set( ( ) => ({ client }) ),
    festival: {} as IFestival,
    land: {} as ILand
})))

export const useFestStore : any = create( devtools( persist<IFestStore>(
    (set:any, get:any) => ({ 
        cardType: CARD_TYPE.NONE,
        setCardType: (cardType:CARD_TYPE) => set(() => ({ cardType })),
        cardSort: CARD_SORT_TYPE.ACS,
        setCardSort: (cardSort: CARD_SORT_TYPE) => set( () => ({cardSort}) ),
        filters: DEFAULT_FILTERS,
        setFilter: (filters: IFestFilters) => set(() => {  
            return {filters}
        }),
        search: "",
        setSearch: (search: string) => set( () => {
            return { search }
        }),
        isVerified: true,
        isNotVerified: true
    }),
    {
        name: 'pe-fest-storage', 
        storage: createJSONStorage( () => localStorage ), 
    } 
)))

export const useFestDataStore: any = create( devtools( persist<IFestDataStor>( 
    (set: any, get: any) => ({
        festId: -1,
        festival: {} as IFestival,
        setFestival: ( festival:IFestival) => set( () => ({ festival }) ),
        lastUpdateFestival : Date.now(),
        setLastUpdateFestival : ( lastUpdateFestival: number ) => set( () => ({ lastUpdateFestival }) ), 
        site: {} as ILand,
        tracks: [] as ITrack[],
        setTracks: (tracks: ITrack[] ) => set( () => ({ tracks }) ),
        honeycombs: [] as IHoneycombs[],
        setHoneycombs: (honeycombs: IHoneycombs[] ) => set( () => ({ honeycombs }) ),
        ganres: [] as IGanre[],
        setGanres: (ganres: IGanre[] ) => set( () => ({ ganres }) ),
        milestones: [] as IMilestone[],
        setMilestones: (milestones: IMilestone[] ) => set( () => ({ milestones }) ),
        categories: [] as ICategory[],
        setCategories: (categories: ICategory[] ) => set( () => ({ categories }) ),
        criteries: [] as ICritery[],
        setCriteries: (criteries: ICritery[] ) => set( () => ({ criteries }) ),
        isPatternAlarm: true,
        isComment: true,
        setIsComment: (isComment:boolean) => {
            console.log( isComment )
            set({isComment : isComment})
        } 
     }),
     {
         name: 'pe-fest-data-storage', 
         storage: createJSONStorage( () => localStorage ), 
     }
)))

export const useFestProjects : any = create( devtools( persist<IFestProjectsProps>(
    (set: any, get: any) => ({
        currentProject : {} as IProject,
        setCurrentProject: (currentProject: IProject ) => set({ currentProject }),
        currentProjectMilestones: [] as IProjectMilestone[],
        setCurrentProjectMilestones: (currentProjectMilestones : IProjectMilestone[]) => set({currentProjectMilestones}),
        isBlockedUpdate: false, 
        setIsBlockedUpdate: ( isBlockedUpdate: boolean ) => set({ isBlockedUpdate }),
        extendFieldsTemp: []
    }),
    {
        name: 'pe-fest-current-projects-storage', 
        storage: createJSONStorage( () => localStorage ), 
    }
)))

export const useFestMyProjects : any = create( devtools( persist<IFestMyProjectsProps>(
    (set: any, get: any) => ({
        myProjects : [] as IProject[],
        setMyProjects: (myProjects: IProject[] ) => set({ myProjects }),
        unverificiedProjects: []  as IProject[],
    }),
    {
        name: 'pe-fest-my-projects-storage', 
        storage: createJSONStorage( () => localStorage ), 
    }
)))

export const useFestStatistic : any = create( devtools (persist<IFestStatistics>(
    (set: any, get:any) => ({
        membersParams: {} as membersParamsProps, 
        setMembersParams : ( membersParams: membersParamsProps ) => set({ membersParams }),
        usersParams: { filters: { tracks:[] } } as usersParamsProps, 
        setUsersParams: ( usersParams: usersParamsProps ) => set({usersParams }),
        specialsParams: {} as specialsParamsProps,
        setSpecialsParams : ( specialParams: specialsParamsProps ) => set({ specialParams }),
        selected: [ "#" ],
        setSelected: (selected: string[] ) => set({selected })
    }),
    {
        name:"pe-fest-statistics",
        storage: createJSONStorage( () => localStorage ), 
    }
)))

export const useFestExport : any = create( devtools( 
    (set: any, get: any) => ({ 
        festival: {},
        land: {},
        item: {},
        clear: () => set({
            festival: {},
            land: {},
            item: {},
            clear: () => clear()
        }, true)
    }) 
))
const clear = () => { 
    try {
        useFestExport.set({
            festival: {},
                land: {},
                item: {},
                clear: () => clear()
        })
    }
    catch(err) {
        console.error(err)
    }    
}

export interface IFestAdminStore {
    client: any
    setClient: ( client: any ) => void
    festival: IFestival
    land: ILand
}

export interface membersParamsProps {
    filters: any,
    sort: "order" | "alphabet" | "rating" | "nomination" | "abc" | "desc",
    fields: "isFields" | "isCriteries"
}

export interface usersParamsProps {
    filters: any,
    sort: "order" | "alphabet",
}

export interface specialsParamsProps {
    filters: any,
    sort: "order" | "alphabet",
    fields: string[],
    selected: string[]
}

// filters for statisctics forms 
export interface IFestStatistics {

    //See ProjectsStatisticForm.tsx
    membersParams: membersParamsProps
    setMembersParams : ( membersParams: membersParamsProps ) => void

    // See FullMemberExpertsForm.tsx
    usersParams : usersParamsProps
    setUsersParams: ( usersParams: usersParamsProps ) => void

    // See SpecialStatisticForm.tsx
    specialsParams: any,
    setSpecialsParams : ( specialParams: any ) => void,

    // ?
    selected: string[],
    setSelected: (selected: string[] ) => void
}

export interface IFestMyProjectsProps {
    unverificiedProjects: IProject[]
    myProjects: IProject[]
    setMyProjects: ( myProjects: IProject[] ) => void
}

export interface IFestProjectsProps {
    currentProject: IProject
    setCurrentProject: (currentProject: IProject) => void    
    currentProjectMilestones: IProjectMilestone[]
    setCurrentProjectMilestones: (currentProjectMilestones : IProjectMilestone[]) => void
    isBlockedUpdate: boolean, 
    setIsBlockedUpdate: (isBlockedUpdate: boolean) => void,
    extendFieldsTemp: IFormField[][]
}

export interface IFestStore {
    cardType: CARD_TYPE,
    setCardType: (cardType: CARD_TYPE) => void,
    cardSort: CARD_SORT_TYPE,
    setCardSort: (cardSort: CARD_SORT_TYPE)=> any
    filters: IFestFilters
    setFilter : (filters: IFestFilters) => void
    search: string,
    setSearch: (search: string) => void,    
    isVerified: boolean,
    isNotVerified: boolean
}

export interface IFestDataStor {
    festId: ID,
    festival: IFestival,
    setFestival: (festival: IFestival) => void,
    lastUpdateFestival: number,
    setLastUpdateFestival : ( lastUpdateFestival: number ) => void 
    site: ILand
    tracks: ITrack[]
    setTracks: (tracks: ITrack[] ) => void
    honeycombs:IHoneycombs[]
    setHoneycombs: (honeycombs: IHoneycombs[] ) => void
    ganres: IGanre[] 
    setGanres: (ganres: IGanre[] ) => void
    milestones: IMilestone[] 
    setMilestones: (milestones: IMilestone[] ) => void
    categories: ICategory[] 
    setCategories: (categories: ICategory[] ) => void
    criteries: ICritery[]
    setCriteries: (criteries: ICritery[] ) => void
    isPatternAlarm: boolean
    isComment: boolean
    setIsComment: (isComment:boolean) => void
}