import { ID } from '@/libs/interfaces/layouts'
import FieldInput from '@/libs/scalars'
import { VerticalType } from '@/libs/scalars/interface/IScalarForm'
import { __ } from '@/libs/utilities'
import { IUser } from '@/settings/zustand'
import { Button, Collapse, Icon, Intent } from '@blueprintjs/core'
import { Fragment, useEffect, useRef, useState } from 'react'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import MemberRatingByCriteries from '../../data/utils/MemberRatingByCriteries'
import RussionRegionList from '../../data/RussianRegionList'
import { IFestival, IProject, IRating, ITrack } from '../../data/interfaces'
import { IFestDataStor, IFestStatistics, specialsParamsProps, useFestDataStore, useFestStatistic } from '../../data/store'
import ProjectFieldRow from './ProjectFieldRow'
import SpecialFieldsEngine from './SpecialFieldsEngine'

interface IProps {
    members: IProject[]
    users: IUser[]
    ratings: IRating[]
}
const ORDER_SORT = "order"
const ALPHABET_SORT = "alphabet"
const RATING_SORT = "rating"
const NOMINATION_SORT = "nomination"
const SORT_ORDER_ASC = "asc"
const SORT_ORDER_DESC = "desc"

const SpecialStatistics = (props: IProps) :JSX.Element => {
    const festival:IFestival = useFestDataStore((state:IFestDataStor) => state.festival ) 
    const tracks: ITrack[] = useFestDataStore((state:IFestDataStor) => state.tracks)
    const specialsParams: specialsParamsProps = useFestStatistic((state: IFestStatistics ) => state.specialsParams )
    const setSpecialsParams = useFestStatistic((state:IFestStatistics) => state.setSpecialsParams)
    const selected : string[] = useFestStatistic(( state:IFestStatistics ) => state.selected )
    const setSelected = useFestStatistic(( state:IFestStatistics ) => state.setSelected )
    const [ isTrackOpen,  setTrackOpen  ]   = useState( false ) 
    const [ isRegionOpen, setRegionOpen ]   = useState( false )
    const [ isFieldOpen,  setFieldsOpen ]   = useState( false ) 
    const [ isRatingMaxOpen,  setRatingMaxOpen ]   = useState( false ) 
    const [ trackFilter,   setTrackFilter ] = useState( 
        !!specialsParams.filters?.tracks.length 
            ?
            specialsParams.filters?.tracks
            :
            tracks.map(track => track.id) 
        || [] 
    )
    console.log( specialsParams.filters  )
    const [ regionFilter, setRegionFilter ] = useState<string[]>( RussionRegionList() )
    const [ regionId, ] = useState( () => {
        const rsID = festival.extendedFields?.findIndex(ff => ff.type === "RussianRegions") 
        return typeof rsID === "number" ? rsID : -1
    } ) 
    const [ sortField, setSortField ]               = useState( RATING_SORT )
    const [sortOrder, setSortOrder]                 = useState( SORT_ORDER_ASC )
    const [ ratingMaxFilter, setRatingMaxFilter ]   = useState( 0 ) 
    
    const [ fields, setFields ] = useState<any[]>( [] ) 
  
    useEffect(() => {
        const fieldsDefault: string[] = [ 
            "#", "Track", "Project", "Tutor", "Project_author",
            ...(festival.extendedFields || []).map((field: any) => field.name ),
            "Rating" 
        ]
        setFields( fieldsDefault ) 
        setSelected( !selected?.length ? fieldsDefault : selected ) 
    }, [ ])

    const tableRef = useRef(null);

    const onTrackFilter = (value: ID[]) =>
    { 
        setTrackFilter( value || [] )
        setSpecialsParams({
            ...specialsParams, 
            filters: {
                ...specialsParams.filters,
                tracks: value
            }
        })
    } 
    const onRegionFilter = (value: string[]) =>
    {  
        setRegionFilter( value || [] )
    }
    const onRatingMaxFilter = (value: number) =>
    {  
        setRatingMaxFilter( Number(value) )
    } 
    const onSortOrder = () =>
    {
        setSortOrder( () => sortOrder === SORT_ORDER_ASC ? SORT_ORDER_DESC : SORT_ORDER_ASC )
    }
    const onSelected = ( _selected: string[] ) => {
        setSelected( _selected ) 
    }

    const sortMembers = (a: IProject, b: IProject ) =>
    {
        switch(sortField)
        {
            case ALPHABET_SORT: 
                const nameA = a.title.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nameB = b.title.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nameA < nameB) {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nameA > nameB) {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1
                }
                return 0
            case NOMINATION_SORT: 
                const nomA = a.nomination.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                const nomB = b.nomination.replace(/\«|\"|\.|\\/gi, "").toUpperCase() 
                if (nomA > nomB) 
                {
                    return sortOrder === SORT_ORDER_ASC ? -1 : 1
                }
                if (nomA < nomB) 
                {
                    return sortOrder === SORT_ORDER_ASC ? 1 : -1 
                }
                return 0
            case ORDER_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    Number( a.order ) - Number( b.order )
                    :
                    Number( b.order ) - Number( a.order )
            case RATING_SORT:
                return sortOrder === SORT_ORDER_ASC
                    ?
                    MemberRatingByCriteries( b ) - MemberRatingByCriteries( a )
                    :
                    MemberRatingByCriteries( a ) - MemberRatingByCriteries( b )
        }
        return 1
    }
    let memberData = props.members
        ?    
        props.members
            .filter( 
                member => {
                    if( tracks.length === 0 ) return true
                    if(!member.track || !member.track.id) return true
                    return trackFilter.filter( 
                        (filter:any) => {                            
                            return filter === member.track?.id
                        }
                    ).length > 0
                })
            .filter(
                member => regionFilter.filter(
                    filter => {
                        if(regionId < 0) return true
                        if( !Array.isArray( member.form_fields  ) || !member.form_fields[ regionId ] )
                        {
                            return regionFilter.length === RussionRegionList().length
                        }
                        if( typeof member.form_fields[ regionId ][ 0 ] === "undefined" )
                        {
                            return true
                        }
                        if(!member.form_fields[ regionId ][ 0 ]) return true
                        return filter === member.form_fields[ regionId ][ 0 ]
                    }
                ).length > 0
            )
            .filter( member => MemberRatingByCriteries( member ) >= ratingMaxFilter )
        :
        []
    memberData.sort( sortMembers )        
    const memberTRs: JSX.Element[] | JSX.Element = memberData.length > 0 
        ?
        memberData.map(( member, index ) => 
        { 
            return <Fragment key={member.id}>
                <tr className='bg-light-strobe  border-top border-secondary border-2'>
                    {
                        selected?.map((s: string) => {
                            return <td key={ s + Math.random() }>
                                <div className='py-2 d-flex align-items-start flex-column '> 
                                    <div className='title'>
                                        { 
                                            !!festival.extendedFields?.filter( field => field.name === s ).length
                                                ?
                                                festival.extendedFields.map( (af, i) => 
                                                {
                                                    return af.name === s && 
                                                        Array.isArray( member.form_fields[ i ] )
                                                            ?
                                                            member.form_fields[ i ]?.map( (r: any) => {
                                                                return <div className="pe-table-cell" key={i +"_" + r.toString() + Math.random() }> 
                                                                    {r.toString()} 
                                                                </div> 
                                                            })
                                                            :
                                                            null 
                                                }) 
                                                :                                            
                                                <ProjectFieldRow
                                                    name={ s }
                                                    index={ index }
                                                    project={ member }    
                                                />
                                        }
                                    </div> 
                                </div>
                            </td>
                        })
                    } 
                </tr>
                 
            </Fragment>
        })
        :
        <div className='alert alert-danger lead text-center mt-5 p-5'>
            {
                __("No elements exists")
            }
        </div>
    const printIt = () =>
    {
        const restorepage = $('body').html(); 
        $( "fest-header" ).hide()
        $( "fest-phase-labels" ).hide()
        $( "fest-head-switcher" ).hide()
        const printPage = $('body').html()
        $('body').empty().html( printPage )
        window.print();
        $('body').html(restorepage); 
    }  
    return <div className='w-100'><div className='mt-1 mb-3 w-100'>
        <div className='align-items-center w-100 d-flex justify-content-md-between justify-content-center flex-md-row flex-column'>
            <div className='d-flex flex-md-row flex-column'>

                 <div className='flex-centered'>
                    <div className='flex-centered descr text-uppercase text-secondary mr-2'>
                        {__("Filters")}:
                    </div>
                    <Button intent={Intent.SUCCESS} minimal={ !isTrackOpen } onClick={() => setTrackOpen( !isTrackOpen ) }>
                        {__("Tracks")} | { trackFilter.length }
                    </Button>
                    <Button intent={Intent.SUCCESS} minimal={ !isRegionOpen } onClick={() => setRegionOpen( !isRegionOpen ) }>
                        {__("Regions")} | { regionFilter.length }
                    </Button> 
                    <Button intent={Intent.SUCCESS} minimal={ !isRatingMaxOpen } onClick={() => setRatingMaxOpen( !isRatingMaxOpen ) }>
                        {__("By rating")} | { ratingMaxFilter }
                    </Button> 
                </div>

                <div className=' flex-centered ml-4 mr-2 descr text-uppercase text-secondary '>
                    {__("Do sort")}:
                </div>
                <Button 
                    intent={Intent.SUCCESS} 
                    minimal={sortField !== ORDER_SORT}
                    onClick={() => setSortField(ORDER_SORT)}
                >
                    {__("By Member's order")}
                </Button>
                <Button 
                    intent={Intent.SUCCESS} 
                    minimal={sortField !== ALPHABET_SORT}
                    onClick={() => setSortField(ALPHABET_SORT)}
                >
                    {__("By alphabet")}
                </Button>
                <Button 
                    intent={Intent.SUCCESS} 
                    minimal={sortField !== RATING_SORT}  
                    onClick={() => setSortField(RATING_SORT)}
                >
                    {__("By rating")}
                </Button> 
                <Button 
                    intent={Intent.SUCCESS} 
                    minimal  
                    onClick={ onSortOrder }
                >
                    <Icon 
                        icon={ sortOrder === SORT_ORDER_ASC 
                            ?  
                            sortField === RATING_SORT
                                ?
                                "sort-numerical-desc"
                                :
                                "sort-asc" 
                            : 
                            sortField === RATING_SORT
                                ?
                                "sort-numerical"
                                :
                                "sort-desc"
                        } 
                        size={20} 
                    />
                </Button>
                <div className='flex-centered'>
                    <Button intent={Intent.SUCCESS} minimal={!isFieldOpen} className=' px-3 py-2 ml-3' onClick={() => setFieldsOpen(!isFieldOpen)}>
                        {__("Fields")}
                    </Button>
                </div> 

            </div>
            <div className='flex-centered'>
                <div className=' display-6 '>{ Array.isArray(memberTRs) ? memberTRs.length : "" }</div> 
                <Button minimal className='px-3 py-2 ml-3' onClick={printIt}>
                    <Icon icon="print" size={21} className="hint hint--top" data-hint={__("Print")} />
                </Button> 
                <DownloadTableExcel
                    filename="projects"
                    sheet="projects"
                    currentTableRef={ tableRef.current }
                >
                    <Button minimal className='px-3 py-2 '>
                        <Icon 
                            icon="th" 
                            size={21} 
                            className="hint hint--top pointer" 
                            data-hint={__("Download XLS")}
                        />
                    </Button>                            
                </DownloadTableExcel> 
            </div>
            
        </div>
        <Collapse isOpen={ isTrackOpen} className=" light-colored ">
            <FieldInput
                type="tags"
                multiple
                vertical={VerticalType.VERTICAL}
                editable
                values={
                    tracks.map(track => {
                        return { _id: track.id, title: track.title, intent: "success" }
                    })
                }
                value={trackFilter}
                onChange={onTrackFilter}
                on={onTrackFilter}
            />
        </Collapse>
        <Collapse isOpen={ isRegionOpen} className=" light-colored ">
            <FieldInput
                type="tags" 
                className={` w-100 input dark form-control `}
                multiple
                vertical={VerticalType.VERTICAL}
                values={ RussionRegionList() }
                editable 
                value={regionFilter}
                onChange={onRegionFilter}
                on={onRegionFilter}
            />
        </Collapse>
        <Collapse isOpen={ isRatingMaxOpen } className=" light-colored ">
            <FieldInput 
                type="slider"  
                title={__("Only more than")}
                commentary={__("Filter all member that rating low this parameter")}
                className="max-width-100 ml-5"
                min={0}
                max={100}
                stepSize={1}
                labelStepSize={5} 
                editable 
                value={ratingMaxFilter}
                onChange={onRatingMaxFilter}
                on={onRatingMaxFilter}
            />
        </Collapse>
        <Collapse isOpen={ isFieldOpen} className=" light-colored ">
            <div className='p-3'>
                <SpecialFieldsEngine
                    selected={selected}
                    setSelected={onSelected}
                    fields={fields}
                />
            </div>
        </Collapse>
    </div>
    <div style={{overflowX: "auto"}}>
        <table 
            className='table table-striped table-hover1 pe-fest-table-statistics w-100' 
            id="members-to-xls"            
            ref={ tableRef }
        > 
            <thead className="thead-dark-33 bg-secondary text-white">
                <tr>
                    {
                        selected?.map(s => {
                            return <th scope={ s } >
                                {__( s )}
                            </th>
                        })
                    } 
                </tr>                
            </thead > 
            <tbody>
               {memberTRs}
            </tbody> 
        </table>
    </div></div> 
} 

export default SpecialStatistics