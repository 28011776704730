import { UPDATE_SINGLE_ACTION } from "src/modules/pe-fest-module/data/actionTypes"
import FestPatternForm from "src/modules/pe-fest-module/views/festival-admin-list/FestPatternForm"
import FestivalSiteGalleryForm from "src/modules/pe-fest-module/views/festival-admin-list/FestivalSiteGalleryForm"
import FestivalSiteOwnerForm from "src/modules/pe-fest-module/views/festival-admin-list/FestivalSiteOwnerForm"
import { Icon } from "@blueprintjs/core"
import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { GET_SINGLE_ACTIONS } from "src/modules/pe-basic-module/data/actionTypes"
import actions from "src/modules/pe-basic-module/data/actions"
import { ILand } from "src/modules/pe-fest-module/data/interfaces"
import { eventTypes } from "src/modules/pe-topos-module/data/mocks/eventTypes"
import { ID } from "src/libs/interfaces/layouts"
import FieldInput from "src/libs/scalars"
import { SCALAR_TYPES, VerticalType } from "src/libs/scalars/interface/IScalarForm"
import { LayoutIcon, Loading, MediaChooser } from "src/libs/useful"
import { __, getContrastColor } from "src/libs/utilities"
import StartMessagesEditForm from "../event/StartMessagesEditForm"

/* Protopia Ecosystem component */
export interface IEventTypeFormProps {
    
}
const EventTypeForm = (props: IEventTypeFormProps): JSX.Element => {
    const sz = 24
    const params = useParams() 
    const [isLoading, setIsLoading] = useState<boolean>( true )
    const [festival, setFestival] = useState<ILand>({} as ILand) 
    const [defaultFest, setDefaultFest] = useState<string>("{}")  
    const [geo, setGeo] = useState<number[][]>( [[ 55.76, 37.64, 10 ]] )  
    useEffect(() => {
        actions(
            GET_SINGLE_ACTIONS, 
            {
                data_type: "Land", 
                id: params.landId,
                landId: params.landId
            }
        )
        .then(( resp ) => {
            setFestival( resp )
            setDefaultFest( JSON.stringify( resp ) )
            setIsLoading( false )
        })
    },[])
    if( isLoading) return <Loading />

    const onDefaultImage = ( url: string, file: File, ID: ID ) => { 
        let fest: ILand = {...festival}
        fest.defaultThumbnail = url
        fest.defaultThumbnailName = file.name
        setFestival( fest )
    }
    const onTitle = ( value: string ) => { 
        let fest: ILand = {...festival}
        fest.title = value 
        setFestival( fest )
    }
    const onDescription = ( value: string ) => { 
        let fest: ILand = {...festival}
        fest.description = value 
        setFestival( fest )
    }
    const onEventTypes = (value: string) => {
        console.log( value)
        let event_types = [...festival.event_types]
        if( !!event_types.filter(e => e === value).length ){
            event_types = event_types.filter(e => e !== value)
        }
        else {
            event_types.push(value)
        }
        setFestival({
            ...festival, 
            event_types 
        })
    }
    const onGallery = (images: string[], images_names: string[] ) => {
        console.log(images, images_names)
        setFestival({
            ...festival, 
            images,
            images_names 
        })
    }
    const onGeo = ( value: number[] ) => {
        setGeo([ value ])
        setFestival({
            ...festival, 
            geo: [ value ]
        })
    }
    const updateFestival = () => {
        actions(
            UPDATE_SINGLE_ACTION,
            {
                data_type: "Land", 
                id: params.landId,
                item: {
                    event_types: festival.event_types,
                    images: festival.images,
                    images_names: festival.images_names,
                    geo
                    // description: festival.description,
                    // defaultThumbnail: festival.defaultThumbnail,
                    // defaultThumbnailName: festival.defaultThumbnailName,
                }
            }
        )
        .then((response: any) => {
            setFestival( response )
            setDefaultFest( JSON.stringify( response ) )
        })
    }
    const onValue = (value: any, field: keyof ILand) => {
        let fest: ILand = {...festival}
        fest[field] = value 
        setFestival( fest )
    }
    return <div className="short-container position-relative mb-5">
        <div 
            className={ ` position-sticky mr-2 mt-2 top` }
            style={{ zIndex: 100 }}
        >
            <div 
                className={` mt-2 position-absolute right btn btn-sm btn-${defaultFest !== JSON.stringify(festival) ? 'danger' : 'secondary opacity_5 untouchble'}`}
                onClick={updateFestival} 
            >
                <Icon icon="tick" className="mr-2" /> {__("Update Festival data")}
            </div>
        </div>
        
        <div className="display-6 my-3">{__("Default image")}</div>
        <div> 
            <div className="row">
                <div className=" col-12 ">
                    <MediaChooser
                        url={festival.defaultThumbnail!}
                        prefix={"icon"}
                        height={230}
                        width={"100%"}
                        padding={5}
                        onChange={ onDefaultImage }
                        id={ festival.defaultThumbnail! }
                        isURLHide={ true }
                        isDescr={ false }
                        hideLib
                        ID="headerImg"
                        limitSize={ 4000000 }
                    />
                    <div className="small mt-1 text-right">{
                        __("This is the header image for the home page.")}
                    </div>
                </div> 
            </div>
        </div>  

        <div className="display-6 mb-3">{__("Set title")}</div>
        <div className="d-flex flex-wrap">
            <FieldInput 					
                vertical={ VerticalType.VERTICAL }
                editable
                value={
                    festival.title   
                    || 
                    "--"
                }
                commentary={__("Content of this page put to «About» link.")}
                type={SCALAR_TYPES.TEXT}  
                onChange={ value => onTitle( value ) }
            />     
        </div>  
 
        <div className="display-6 my-3">{__("View mode")}</div>
        <div> 
            <div className="row">
                <div className=" col-6 ">
                     
                </div> 
                <div className=" col-6 ">
                     
                </div> 
            </div>
        </div> 

        <div className="display-6 my-3">{__("Description")}</div>
        <div>
            <FieldInput 					
                vertical={ VerticalType.VERTICAL }
                editable
                value={
                    festival.description   
                    || 
                    "--"
                }
                commentary={__("Content of this page put to «About» link.")}
                type={SCALAR_TYPES.TEXT}  
                onChange={ value => onDescription( value ) }
            />
        </div>
        <div className="display-6 my-3">{__("Set owner")}</div>
        <div>
            <FestivalSiteOwnerForm item={festival} className="short-container"/>
        </div>
        <div className="display-6 my-3">{__("Set gallery")}</div>
        <div>
            <FestivalSiteGalleryForm item={festival} onChange={onGallery} />
        </div>
        <div className="display-6 my-3">{__("Geo position")}</div>
        <div className=" "> 
            <FieldInput
                type={SCALAR_TYPES.GEO}
                value={ 
                    Array.isArray(festival.geo) 
                        ? 
                        (festival.geo[0] || [ 55.76, 37.64, 10 ])
                        :
                        [ 55.76, 37.64, 10 ]
                }
                onChange={onGeo}
                vertical={VerticalType.VERTICAL}
                editable
                width="100%"
                notClear
            />
        </div>
        <StartMessagesEditForm 
            newProjectEmail={festival.newProjectEmail || ""}
            newProjectMessage={festival.newProjectMessage || ""}
            onValue={onValue}
        />
        <div className="display-6 my-3">{__("Set by Pattern")}</div>
        <div>
            <FestPatternForm item={festival} />
        </div>
    </div>
}
export default EventTypeForm